import axios from 'axios'

// let base = '.168.1.66:8081';

export const getUserAddress = () => {
  return axios.get(`${base}/address`)
};

export const delAddress = (params) => {
  return axios.get(`${base}/del`, { params });
};

export const productCateUnderTopCate = (params) => {
  var url = '/productCate/underTopCate?id=' + params;
  return axios.get(url);
}

export const recommendHotProduct = () => {
  var url = '/recommend/hot/product';
  return axios.get(url);
}
export const cateRecommend = () => {
  var url = 'pic/adv/config/new/list?code=pc_sy_jpzqxf_lbt';
  return axios.get(url);
}

export const productGetListByCateId = (params) => {
  var url = `/product/getListByCateId?cateId=${params.cateId}&pageNum=${params.pageNum}&pageSize=${params.pageSize}`;
  return axios.get(url);
}

export const recommendGetSecCate = (params) => {
  var url = `/recommend/getSecCate?cateId=${params.cateId}`;
  return axios.get(url);
}

export const picAdvConfigList = (params) => {
  var url = `/pic/adv/config/list?advPositionId=${params.advPositionId}`;
  return axios.get(url);
}

export const storeList = (params) => {
  var url = `/store/list?pageNum=${params.pageNum}&pageSize=${params.pageSize}`;
  return axios.get(url);
}

export const myneeds = (params) => {//我的需求接口数据
  var url = `/bidInfo/findByBuyUserId?page=${params.page}&rows=${params.rows}&state=${params.state}`;
  return axios.get(url);
}

export const needDetail = (params) => {//需求详情以及二级需求详情接口
  var url = `/bidInfo/findByBidId?id=${params.id}`;
  return axios.get(url);
}

export const needViewQuotation = (params) => {//需求详情以及二级需求详情接口
  var url = `/bidInfo/viewQuotation?bidSn=${params.id}&storeId=${params.storeId}`;
  return axios.get(url);
}


export const myPrice = (params) => {//我的比价
  var url = `/inviteInfo/findAllByUserId?page=${params.page}&rows=${params.rows}&state=${params.state}`;
  return axios.get(url);
}

export const priceDetail = (params) => {//比价详情
  var url = `/inviteInfo/findByInviteId?id=${params.id}`;
  return axios.get(url);
}

export const purchaseOrder = (params) => {//购买订单,以及招标订单
  var url = `/tOrder/findByBuyerId?page=${params.page}&rows=${params.rows}&type=${params.type}&txt=${params.txt}&status=${params.status}`;
  return axios.get(url);
}

export const cancelOrder = (params) => {//购买订单=>取消订单
  var url = `/tOrder/updateStatus?orderSn=${params.orderSn}&status=${params.status}`;
  return axios.get(url);
}

export const orderDetail = (params) => {//购买订单详情
  var url = `/tOrder/findByOrderSn?orderSn=${params.orderSn}`;
  return axios.get(url);
}

export const deleteById = (params) => {//个人中心=>地址管理=>删除地址接口
  var url = `/userConsignee/deleteById?userConsigneeId=${params.userConsigneeId}`;
  return axios.get(url);
}

export const buyAgain = (params) => {//个人中心=>订单管理=>再次购买
  var url = `/tOrder/buyAgain?orderSn=${params.orderSn}`;
  return axios.get(url);
};
export const updateIsDefault = (params) => {//个人中心=>地址管理=>用户设置默认地址
  var url = `/userConsignee/updateIsDefault?userConsigneeId=${params.userConsigneeId}`;
  return axios.get(url);
};
export const getMyfs = (params) => {//个人中心=>推广数据=>列表数据
  var url = `/buyer/getMyfs?phone=${params.phone}&page=${params.page}&rows=${params.rows}`;
  return axios.get(url);
};
export const getPersonJFDetail = (params) => {//根据手机号获取积分明细
  var url = `/buyer/getPersonJFDetail?phone=${params.phone}&jftype=${params.jftype}&pageNum=${params.page}&pageSize=${params.rows}`;
  return axios.get(url);
};
export const genJFShopLink = (params) => {//生成积分商城的跳转链接
  var url = `/buyer/genJFShopLink?mobile=${params.phone}`;
  return axios.get(url);
};
