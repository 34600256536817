<template>
 <div class="demandDetail">
    <div class="nav">
        <p class="nav_p">您的位置>个人中心>我的需求>需求详情</p>
      
    </div>
   <div class="content">
       
       <div class="bg1">
           {{changeType(arr.state)}}
       </div>
       <div class="main"> 
            <div class="title">
                <h2>{{arr.title}}</h2>
                <div class="title-bottom">
                    <div class="title-bottom-l">
                        <span>需求编号:</span>
                        <span>{{arr.inviteId}}</span>
                    </div>
                    <div class="title-bottom-r">
                        <span class="title-bottom-r-1">发布时间:</span>
                        <span>{{arr.createDate}}</span>
                    </div>
                </div>             
            </div> 

            <div class="info">
                <h3>需求信息</h3>
                <div class="info-top">
                    <p>
                        <span>需求预算：</span>
                        <span>{{arr.budgetAmount}}元</span>
                    </p>
                    <p>
                        <span>剩余天数：</span>
                        <span>{{arr.residueDate}}天</span>
                    </p>
                    <p>
                        <span>联系人：</span>
                        <span>{{arr.linkman}}</span>
                    </p>
                    <p>
                        <span>联系电话：</span>
                        <span>{{arr.mobile}}</span>
                    </p>
                </div>

                <div class="info-bottom">                 
                    <div class="info-bottom-l">
                        截止时间:{{arr.endDate}}
                    </div>
                    <div class="info-bottom-r">
                        {{arr.userConsignee}}
                    </div>              
                </div>
            </div>

            <div class="description">
                <h3>需求描述</h3>
                 <div class="node">
                    {{ content }}
                    <span @click='handleFold(false)' v-show="maxLen  != haystack.length" v-if="flag">
                        展开&or;
                    </span>
                    <span @click='handleFold(true)' v-show="maxLen  == haystack.length">
                        收起
                    </span>
                </div>
            </div>

       </div>

        <div class="side" v-for="(item,i) in arrRight" :key="i">
            <h3>报价商家</h3>
            <p>
                当前共有
                <span>{{leg}}</span>
                家服务商参加了报价
            </p> 
            
            <ul v-for="(item,i) in arrRight" :key="i" >
                <li>
                    <div class="li_left">
                        <img :src="item.logoUrl | imagePath" alt="" />
                    </div>
                    <div class="li_center">
                        <span>￥{{item.price}}</span>
                        <span>{{item.storeName}}</span>
                    </div>
                    <div class="li_right">
                        <!-- <router-link :to="'/demandDetail/'+arr.inviteId" tag="div" ><span>查看报价单</span></router-link> -->
                        <router-link :to="'/demandDetail/'+arr.inviteId+'/'+item.id" tag="div" ><span>查看报价单</span></router-link>
                        <span class="bg_teshu">{{item.createDate}}</span>
                    </div>
                </li>
            </ul>
        </div>
    </div>
    
</div>


</template>

<script>
    import * as api from '@/api/api';
    import Vue from 'vue';
    import Header from '../../../components/common/header.vue';
    import Footer from '@/components/common/footer.vue';
    import Aside from "@/components/common/aside.vue";

    const maxLen = 60;
    export default {
        name: "index",
        components: { Header,Footer,Aside},//注册组件
        data() {
            return {
                haystack: "",
                 maxLen: maxLen,
                 flag:true,
                arr:[],
                arrRight:[],
                leg:"",
                str:""
            }
        },
        computed: {
             content() {
            const maxLen = this.maxLen;//120
            const haystack = this.haystack.toString();//原本字符长度
           
                if(haystack.length > maxLen){
                    this.flag=true;
                    return haystack.slice(0, maxLen) + "..."
                }else{
                    this.flag=false;
                    return haystack;
                }
            }
        },
        methods: {
            handleFold(fold) {
            this.maxLen = fold ? maxLen : this.haystack.length;//点击展开九让最大长度等于原先的字符长度
            },
            getData: function() {
            setTimeout(() => {
                this.haystack =this.str;
                
                }, 150);
            },
             needDetail() { //需求详情首次加载数据
                 let id = this.$route.params.id;
                let params = {id:id}
                let _this = this;
                api.needDetail(params).then(res => {
                    let arr = res.data.data;
                    _this.arr=arr; //左侧数据
                    _this.arrRight=res.data.data.bidOfferList; //右侧商家数据
                    _this.leg=res.data.data.bidOfferList.length; //服务商总长度
                    _this.str=arr.requirementDesc;
                    console.log(res)
                });
             },
             changeType(str) {
                return str == 0 ? "审核中" : str == 1 ? "招标中" : str == 2 ? "审核不通过" : str == 3 ? "已完成" : str == 4 ? "已关闭" :'';
            }
        },
       
        mounted() {   
           this.needDetail()
            this.getData();
        }
    }

</script>

<style lang="scss" scoped>
    @import "../../../assets/scss/common";
   
    *{
        margin:0;
        padding:0;
    }
    h2,ul,li{
        margin:0;
        padding:0
    }
    a{
        text-decoration: none;
    }
    li{
        list-style: none;

    }
    h2,h3{
        font-weight: 100;

    }
    .nav{
            height:44px;
            width: 1200px;
            margin:0 auto;
            margin-top: 20px;
            color:#666;
            line-height: 44px;
            font-size: 14px;
            a{
                cursor: pointer;
            }
           .nav_p{
               margin-top: -20px ;
           }
        }
     .demandDetail{
        width: 100%;    
        background:#f5f5f5;
        font-size: 15px;
        .content{
            position: relative;
             width: 1200px;
            height: 1240px;
            background:#fff;
            margin:0 auto;
            overflow: hidden;
            .bg1{
                width: 140px;
                height: 50px;
                line-height: 52px;
                color:#fff;
                background: #adacaa;
                font-size: 20px;
                text-align: center;
                border-radius: 30px;
                margin:20px 0 40px -30px;
                padding-left: 15px;
            }
            .main{
                padding-left: 30px;
                .title{
                    width: 740px;
                    height: 100%;
                    color: #333;
                    h2{
                        height: 30px;
                        width: 200px;
                        overflow: hidden;
                       white-space: nowrap; 
                       text-overflow: ellipsis;
                        line-height: 26px;
                        font-size: 24px;
                    }
                   .title-bottom{    
                             height: 50px;
                            line-height: 50px;     
                            color: #999;
                            font-size: 14px;    
                            >div{
                                float: left;
                            }                                 
                        .title-bottom-r-1{
                            margin-left: 38px;
                        }
                    }
                }
                .info{
                    border-bottom: 1px solid #e5e5e5;
                    width: 740px;
                    h3{
                        background: url("../../../assets/image/biaotijuxing.png") no-repeat 0 11px;
                        font-size: 18px;
                        padding-left: 15px;
                        background-size: 4px 18px;
                        height: 38px;
                        line-height: 38px;
                    }
                    .info-top{
                          p{
                                height: 34px;
                                line-height: 34px;
                                font-size: 16px;
                                span{
                                    &:first-child{
                                        color: #666;
                                    }                           
                                }
                            }
                    }
                    .info-bottom{
                        width: 741px;
                        height: 46px;
                       
                        >div{
                       
                            padding-left: 24px;
                            height: 38px;
                            line-height: 38px;
                            color: #9c9c9c;
                            font-size: 14px;
                            margin-top: 7px;
                        }
                        :first-child{
                            float: left;
                            background: url("../../../assets/image/jiezhishijian.png") no-repeat 0 11px;
                            background-size: 15px 15px;
                        }
                        :last-child{
                            float: left;
                            margin-left: 31px;
                            background: url("../../../assets/image/dingwei.png") no-repeat 0 11px;
                            background-size: 15px 15px;
                        }
                        .info-bottom-l-quote{
                            margin-left: 0;
                        }
                        
                    }

                  
                }
               .quote-info{
                
                    margin-top: 49px;
                     .price{
                        color:#ff425e;
                    }
                }
                .description,.detail{
                    position: relative;
                    width: 710px;
                    height: 100%;
                    
                     h3{
                        font-size: 18px;
                        padding-left: 15px;
                        background: url("../../../assets/image/biaotijuxing.png") no-repeat 0 19px;
                        background-size: 4px 18px;
                        height: 54px;
                        line-height: 54px;
                    }
                 
                        .node,.node1{            
                        width: 100%;
                        padding:8px 10px;
                        padding-right: 20px;
                        height: 100%;
                        background: #f5f5f5;
                        line-height: 30px;         
                        span{
                            color: #38be82;
                        }              
                    }
                    .dropDown{ 
                            right: 0;
                            bottom: 0;
                            position: absolute;
                            right: -10px;
                            bottom: 5px;
                            color: #38be82;
                            cursor: pointer;
                        }
                }
            }
            
           .side{
               width: 410px;
               height: 688px;
               position: absolute;
               top: 0px;
               right: 0px;
                 h3{
                        font-size: 18px;
                        padding-left: 15px;
                        background-size: 4px 18px;
                        height: 38px;
                        line-height: 38px;
                        margin: 29px 0px 20px 29px;
                        font-size: 18px;
                        background: url("../../../assets/image/biaotijuxing.png") no-repeat 0 10px;
                        background-size: 4px 20px;
                    }
                p{
                    height: 34px;
                    line-height: 34px;
                    font-size: 16px;
                    width: 353px;
                    margin-left: 29px;
                    margin-bottom:20px;
                    span{
                        color:#38be82;
                    }
                }
                li{
                    width:353px;
                    height: 65px;
                    margin:0 0 30px 30px;
                    .li_left{
                             width: 65px;
                            height: 65px;
                            float: left;
                        img{
                            display: block;
                            width:100%;
                            height: 100%;
                        }
                    }
                    .li_center{
                             width: 113px;
                            height: 65px;
                            float: left;
                            text-align: center;
                            margin: 0 10px;
                            span{
                                display: block;
                            }
                            :first-child{
                                margin-top: 9px;
                                color: #ff0036;
                            }
                            :last-child{
                                margin-top: 15px;
                                overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            }
                       
                    }
                    .li_right{
                             width: 155px;
                            height: 65px;
                            float: left;
                            text-align: right;
                            span{
                                display: block;
                            }
                            :first-child{
                                text-align: center;
                                cursor: pointer;
                                line-height: 30px;
                                width: 94px;
                                height: 30px;
                                background: #38be82;
                                color: #fff;
                                font-size: 14px;
                                border-radius: 20px;
                            }
                            &:last-child{
                                
                                height: 22px;
                                line-height: 22px;
                                color:#9c9c9c;
                                font-size: 14px;
                               
                            }
                            .bg_teshu{
                                     background: url("../../../assets/image/jiezhishijian.png") no-repeat 0 4px;
                                     margin-top: 15px;
                            }
                           >div{
                               margin-left: 62px;
                           }

                       
                    }
                    
                }

           }
        }
    }
    #bussineInfo{
        width:242px;
    }
   
</style>
